<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Navigation
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section>
                <v-section-heading>
                    <template v-slot:default>
                        View Order
                    </template>

                    <template v-slot:description>
                        A list of all the orders in the application.
                    </template>

                    <template v-if="menu.length > 1" v-slot:actions>
                        <div class="block lg:hidden">
                            <v-button color="primary" @click.stop="toggleSidebar">
                                <i class="fa fa-list-ul" />
                            </v-button>
                        </div>
                    </template>
                </v-section-heading>

                <template v-if="isLoading">
                    <v-loader />
                </template>

                <template v-else>
                    <v-model-form :id="$route.params.order" module="orderModule">
                        <template v-slot:default="{ model, refreshModel }">
                            <v-section>
                                <v-ghost :order="model" @order-updated="refreshModel" />
                            </v-section>
                        </template>
                    </v-model-form>
                </template>
            </v-section>
        </template>
    </v-main>
</template>

<script>
import SectionHeading from "@/layouts/panel/section-heading.vue";
import Main from "@/layouts/panel/main.vue";

export default {
    name: "OrderShow",
    components: {
        "v-section-heading": SectionHeading,
        "v-main": Main,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},

            /**
             * @todo Write a description.
             */
            menu: [
                {
                    label: "Order Details",
                    route: { name: "orders.show.summary" },
                    description: "View information about the order including jobs for the order.",
                    icon: "fa fa-id-card",
                },
                // {
                //     label: "Order Fees",
                //     route: { name: "orders.show.order-fees" },
                //     description: "Additional costs that the order has aside from the jobs.",
                //     icon: "fa fa-money-check-alt",
                //     permissions: ["order-fees.read"],
                // },
                // {
                //     label: "Jobs",
                //     route: { name: "orders.show.jobs" },
                //     description: "All the jobs generated from orders created by the user.",
                //     icon: "fa fa-business-time",
                //     permissions: ["order-lines.read"],
                // },
            ],
        };
    },
};
</script>
